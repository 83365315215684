import $ from 'jquery-slim';

class Toggle
{
    constructor($element)
    {
        this.$element = $element;

        let toggle = this.$element.attr('data-external-toggle');
        let target = this.$element.attr('data-target');

        this.$tab = $('[data-toggle="' + toggle + '"][data-target="' + target + '"]');


        this.$element.on('click', this.toggle.bind(this));

        this.$tab.on('hide.bs.tab', this.hide.bind(this));
        this.$tab.on('show.bs.tab', this.show.bind(this));

        if (this.$tab.hasClass('active')) this.show()
        else this.hide();
    }

    show()
    {
        this.$element.addClass('active');
    }

    hide()
    {
        this.$element.removeClass('active');
    }

    toggle()
    {
        if (!this.$tab.hasClass('active')) this.$tab.tab( 'show');
    }
}

class OptExternalToggle
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        let $elements = $('[data-external-toggle]');

        this.toggles = [];

        for (let i = 0; i < $elements.length; i++) this.toggles.push(new Toggle($elements.eq(i)));
    }

}

const externalToggle = new OptExternalToggle();






